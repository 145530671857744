import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import $ from 'jquery'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import InfraccionesPago from './InfraccionesPago.js'
import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import Config from '../../commons/config/Config.js'
import swal from 'sweetalert'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
import LogoCompany from '../../assets/images/logo-company.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import LimpiarFiltros from '../../assets/images/limpiar-filtros.svg'
import SurtidorVerde from '../../assets/images/combustibles/control_de_carga1.svg'
import SurtidorRojo from '../../assets/images/combustibles/control_de_carga2.svg'
import DollarGris from '../../assets/images/infracciones/dollar-symbol.svg'
import AutoVerde from '../../assets/images/combustibles/control_entre_carga1.svg'
import AutoRojo from '../../assets/images/combustibles/control_entre_carga2.svg'
import AutoGris from '../../assets/images/combustibles/control_entre_carga3.svg'
import Security from '../../commons/security/Security.js'
import moment from 'moment'
import Loading from '../ui/Loading.js'
import WidgetSparkline from '../dashboards/operativo2/WidgetSparkline';
import WidgetMapChart from '../dashboards/operativo2/WMapChart';
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import GravatarOption from './GravatarOption'
import { random } from '@amcharts/amcharts4/.internal/core/utils/String';
import Switch from "react-switch"
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import AdvancedCardInfo from '../ui/AdvancedCardInfo.js'
import Calendario from '../../assets/images/infracciones/calendario.svg';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js';
import Timezone from '../../commons/timezone/Timezone.js';

$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;


class InfraccionesGrid extends Component {
	constructor(props) {
		super(props);
        this.dataService = '/infracciones/grid';
		this.apiBaseUrl = process.env.REACT_APP_VEC_FLEET_API_BASE_URL;
		this.ajaxHandler = new AjaxHandler();
		this.columnsToPrint = [0,1,2,3,4,5,6,7];

		this.state = {
			redirectTo: null,
			seleccionado: "total",
			loading: false,
			filtroPeriodoInicio: null,
			filtroPeriodoFin: null,
			periodos: [
				{id:1, label: this.props.intl.formatMessage({ id: 'infraccionesGrid.filter_period.select_title_hoy', defaultMessage: 'Hoy' })},
				{id:2, label: this.props.intl.formatMessage({ id: 'infraccionesGrid.filter_period.select_title_ayer', defaultMessage: 'Ayer' })},
				{id:7, label: this.props.intl.formatMessage({ id: 'infraccionesGrid.filter_period.select_title_ultimos_siete_dias', defaultMessage: 'Últimos 7 días' })},
				{id:14, label: this.props.intl.formatMessage({ id: 'infraccionesGrid.filter_period.select_title_ultimos_catorse_dias', defaultMessage: 'Últimos 14 días' })},
				{id:20, label: this.props.intl.formatMessage({ id: 'infraccionesGrid.filter_period.select_title_ultimos_treinta_dias', defaultMessage: 'Últimos 30 días' })},
				{id:30, label: this.props.intl.formatMessage({ id: 'infraccionesGrid.filter_period.select_title_este_mes', defaultMessage: 'Este mes' })},
				{id:60, label: this.props.intl.formatMessage({ id: 'infraccionesGrid.filter_period.select_title_mes_pasado', defaultMessage: 'Mes Pasado' })},
				{id:365, label: this.props.intl.formatMessage({ id: 'infraccionesGrid.filter_period.select_title_este_anio', defaultMessage: 'Este año' })},
				{id:400, label: this.props.intl.formatMessage({ id: 'infraccionesGrid.filter_period.select_title_anio_pasado', defaultMessage: 'Año Pasado' })},
				{id:0, label: this.props.intl.formatMessage({ id: 'infraccionesGrid.filter_period.select_title_todo', defaultMessage: 'Todo' })},
				{id:12, label:""}
			],
			unidad: 'day',
			estadisticas: [],

			dataToExport : {
				dominio:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'infraccionesGrid.tools_export.column_dominio', defaultMessage: 'Dominio' })
				},
				importe:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'infraccionesGrid.tools_export.column_importe', defaultMessage: 'Importe' })
				},
				importeAbonado:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'infraccionesGrid.tools_export.column_importe_abonado', defaultMessage: 'Importe Abonado' })
				},
				fuente:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'infraccionesGrid.tools_export.column_fuente', defaultMessage: 'Fuente' })
				},
				fechaEmision:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'infraccionesGrid.tools_export.column_fecha_emision', defaultMessage: 'Fecha de Emisión' })
				},
				fechaVencimiento:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'infraccionesGrid.tools_export.column_fecha_vencimiento', defaultMessage: 'Fecha de Vencimiento' })
				},
				fechaAbono:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'infraccionesGrid.tools_export.column_fecha_abono', defaultMessage: 'Fecha de Abono' })
				},
				codigoInfraccion:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'infraccionesGrid.tools_export.column_codigo_infraccion', defaultMessage: 'Código de Infracción' })
				},
				infraccion:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'infraccionesGrid.tools_export.column_descripción Infraccion', defaultMessage: 'Descripción Infracción' })
				},
				descripcion:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'infraccionesGrid.tools_export.column_detalle', defaultMessage: 'Detalle' })
				},
				baseDescripcion:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'infraccionesGrid.tools_export.column_base', defaultMessage: 'Base' })
				},
				direccion:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'infraccionesGrid.tools_export.column_direccion', defaultMessage: 'Dirección' })
				},
				latitud:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'infraccionesGrid.tools_export.column_latitud', defaultMessage: 'Latitud' })
				},
				latitudDetectadaAvl:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'infraccionesGrid.tools_export.column_latitud_AVL', defaultMessage: 'Latitud AVL' })
				},
				longitud:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'infraccionesGrid.tools_export.column_longitud', defaultMessage: 'Longitud' })
				},
				longitudDetectadaAvl:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'infraccionesGrid.tools_export.column_longitud_AVL', defaultMessage: 'Longitud AVL' })
				},
				conductorName:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'infraccionesGrid.tools_export.column_conductor', defaultMessage: 'conductor' })
				},
				region:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'infraccionesGrid.tools_export.column_region', defaultMessage: 'Región' })
				},
				subRegion:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'infraccionesGrid.tools_export.column_sub_region', defaultMessage: 'Sub Región' })
                },
                acta:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'infraccionesGrid.tools_export.column_acta', defaultMessage: 'Acta' })
                },
                pagada:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'infraccionesGrid.tools_export.column_abonada', defaultMessage: 'Abonada' })
				},
			},
			ExportarTodo : true,
			bases: [],
			centroCostos: [],
			periodo: null,
			base: null,
			centroCosto: null,
			infraccionesAnterioresFechaAlta: false,
			periodoSelect: {id:0, label: this.props.intl.formatMessage({ id: 'infraccionesGrid.filter_period.select_initial_todo', defaultMessage: 'Todo' })},
			dialogInformarPago:{
				dominio: null,
				open: false,
				id: null
			},
			filtersColumns: {},
			summaryAlerts: null,
			alertClick: "total",
			alertClickChild: null,
			alertsClickedChild: [],
			alertsClicked: {
				"pagadas": false,
				"pendientes_confirmacion": false,
				"pendientes_pago": false,
				"total": true
			},
			movilDetail: null,
			ultimaActualizacion: ''
		}

		this.handleAlertClick = this.handleAlertClick.bind(this);
		this.selectedColor = "#e2e2e2";
		this.selectedColor2 = "#e2e2e2";

		this.handleSelectFormChange = this.handleSelectFormChange.bind(this);
		//this.handleRegionChange = this.handleRegionChange.bind(this);
		this.handleCloseModal = this.handleCloseModal.bind(this);
		this.handleCancelModal = this.handleCancelModal.bind(this);
		this.handleInformarPagoModal = this.handleInformarPagoModal.bind(this);
		// this.getUltimaActualizacion = this.getUltimaActualizacion.bind(this);

		this.textos = [];
		this.textos['widget_gasto_total'] = this.props.intl.formatMessage({ id: 'infraccionesGrid.widget_gasto_total', defaultMessage: 'Gasto total de Infracciones' });
		this.textos['widget_gasto_pendiente'] = this.props.intl.formatMessage({ id: 'infraccionesGrid.widget_gasto_pendiente', defaultMessage: 'Gasto de Infracciones Pendientes por Pagar' });
		this.textos['widget_infracciones_labradas'] = this.props.intl.formatMessage({ id: 'infraccionesGrid.widget_infracciones_labradas', defaultMessage: 'Cantidad de Infracciones labradas' });
		this.textos['widget_infracciones_abonadas'] = this.props.intl.formatMessage({ id: 'infraccionesGrid.widget_infracciones_abonadas', defaultMessage: 'Cantidad de Infracciones Abonadas pendientes de libre deuda' });
		this.textos['widget_map_location'] = this.props.intl.formatMessage({ id: 'infraccionesGrid.widget_map_location', defaultMessage: 'Infracciones por Provincias' });

		this.map = null;
		this.marker = null;
	}

	handleAlertClick(filter,filterChild){
		let copyClicked = this.state.alertsClicked;
		let copyClickedChild = this.state.alertsClickedChild;
		//si el filtro estaba seleccionado y se vuelve a clickear, se resetea
		let reset = false;

		if(this.state.alertsClicked[filter]){
			if(!!filterChild){
				for(let alarmChild in this.state.alertsClickedChild){
					copyClickedChild[alarmChild] = false;
				}
				copyClickedChild[filterChild] = !this.state.alertsClickedChild[filterChild];
			} else {
				if(this.state.alertsClickedChild[filterChild]){
					copyClickedChild[filterChild] = !this.state.alertsClickedChild[filterChild];
				}
				else {

					let mantenerEstado = true;

					for(let alarmChild in this.state.alertsClickedChild){
						if(copyClickedChild[alarmChild]) mantenerEstado = false;
						copyClickedChild[alarmChild] = false;
					}

					if(mantenerEstado) {
						reset = true;
						copyClicked[filter] = !this.state.alertsClicked[filter];
					}
				}
			}
		} else{

				for(let alarmChild in this.state.alertsClickedChild){
					copyClickedChild[alarmChild] = false;
				}
				copyClickedChild[filterChild] = true;

				for(let alarm in this.state.alertsClicked){
					copyClicked[alarm] = false;
				}
				copyClicked[filter] = true;




		}




		this.setState({loading:true});
		this.setState({
			alertClick: reset ? null : filter,
			alertClickChild: reset ? null : filterChild,
			alertsClicked: copyClicked,
			alertsClickedChild: copyClickedChild,
			movilDetail: null
		}, () => {
			this.cargarDatosFiltrados().finally(()=>{
				this.setState({loading:false});
			});
		});

		if(reset){
			this.seleccionado = null;
		} else {
			if(!!filterChild) this.seleccionado = filterChild
			else this.seleccionado = filter;
		}
	}

	handleSelectFormChange(name, object) {

		this.setState({ loading: true });

		if(name === "periodo"){
			if (object) {
				this.setState({
					periodoSelect: object,
				}, ()=>{

				let inicio = "";
				let fin = "";
				if(object && object.id !== 12){

					switch (object.id) {
						case 0:
							inicio = null;
							fin = null;
							break;
						case 1:
							inicio = moment().format('YYYY-MM-DD');
							fin = moment().format('YYYY-MM-DD');
							break;
						case 2:
							inicio = moment().subtract(1, 'days').format('YYYY-MM-DD');
							fin = inicio;
							break;
						case 7:
							inicio = moment().subtract(7, 'days').format('YYYY-MM-DD');
							fin = moment().format('YYYY-MM-DD');
							break;
						case 14:
							inicio = moment().subtract(14, 'days').format('YYYY-MM-DD');
							fin = moment().format('YYYY-MM-DD');
							break;
						case 20:
							inicio = moment().subtract(30, 'days').format('YYYY-MM-DD');
							fin = moment().format('YYYY-MM-DD');
							break;
						case 30:
							inicio = moment().startOf('month').format('YYYY-MM-DD');
							fin = moment().format('YYYY-MM-DD');
							break;
						case 60:
							inicio = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
							fin = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
							break;
						case 400:
							inicio = moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
							fin = moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD');
							break;
						default:
							inicio = moment().startOf('year').format('YYYY-MM-DD');
							fin = moment().format('YYYY-MM-DD');
					}

				}else if(object.inicio){
					inicio = moment(object.inicio).format('YYYY-MM-DD');
					fin = moment(object.fin).format('YYYY-MM-DD');
				}
				this.setState({filtroPeriodoInicio: inicio, filtroPeriodoFin: fin}, ()=>{
					this.cargarDatosFiltrados().finally(()=>{
						this.setState({loading:false});
					});
				});

				});
			}
				this.setState({loading:false});
		}else if(name === 'infraccionesAnterioresFechaAlta'){
			this.setState({	infraccionesAnterioresFechaAlta: !this.state.infraccionesAnterioresFechaAlta}, ()=>{
				this.cargarDatosFiltrados().finally(()=>{
					this.setState({loading:false});
				});
			});
		}else{

			this.setState({	[name]: object}, ()=>{
				this.cargarDatosFiltrados().finally(()=>{
					this.setState({loading:false});
				});
			});

			}

		 }



		handleInformarPagoModal(message, subtitle, error, modal="#pagarInfranccion_modal"){
			$(modal).modal('toggle');
			this.setState({loading:true});
			this.cargarDatosFiltrados().finally(()=>{
				this.setState({loading:false});
			});
		}

		 handleCancelModal(message, subtitle, error, modal="#pagarInfranccion_modal"){
			$(modal).modal('toggle');
		 }

		 handleCloseModal(message, subtitle, error, modal="#pagarInfranccion_modal"){
			 $(modal).modal('toggle');

			if(message !== "" && error){
				swal(this.props.intl.formatMessage({ id: 'infraccionesGrid.pay_modal_infraction.information_Error', defaultMessage: 'Error' }), message, "error");
			} else{
				swal(message, subtitle ? this.props.intl.formatMessage({ id: 'infraccionesGrid.pay_modal_infraction.information_filas_que_no_se_pudieron_insertar: ', defaultMessage: 'Filas que no se pudieron insertar: ' })+subtitle : "", "success").then((data)=>{
					this.table.ajax.reload(null, false);
				});

			}
		 }

		 cargarDatosFiltrados(){

			 return new Promise((resolve, reject)=>{
                this.table.ajax.url(Config.get('apiUrlBase') + this.dataService +'/'+ this.state.filtroPeriodoInicio+'/'+this.state.filtroPeriodoFin).load();
				let unidad = this.calcularUnidadTiempo().then((unidad)=>{

				let url = new URL(Config.get('apiUrlBase') + '/infracciones/sparkline/'+this.state.filtroPeriodoInicio+'/'+this.state.filtroPeriodoFin+'/'+unidad);

				if(this.state.base){
					let bases = new Array();
					this.state.base.forEach((element, index )=> {
						url.searchParams.append('bases['+index+'][value]',element.value);
						url.searchParams.append('bases['+index+'][label]',element.label);
					});
				}else{
					url.searchParams.append('bases', '');
				}

				if(this.state.centroCosto)
					this.state.centroCosto.forEach((element, index ) => {
						url.searchParams.append('centroCostos['+index+'][value]',element.value);
						url.searchParams.append('centroCostos['+index+'][label]',element.label);
					});
				else{
					url.searchParams.append('centroCostos', '');
				}

				url.searchParams.append('infraccionesAnterioresFechaAlta', this.state.infraccionesAnterioresFechaAlta);

					Promise.all([
						fetch(url, {
							method: 'POST',
							body: JSON.stringify({
								filterColumns: this.state.filtersColumns
							}),
							headers: {
								'Accept': 'application/json',
								'Content-Type': 'application/json',
								'Authorization-Token': localStorage.getItem("token")
							}
						}),
					]).then((data) => {
						data[0].json().then((dataEstadisticas)=>{
							this.setState({estadisticas: dataEstadisticas || []},()=>{
								resolve()
							});
						}).catch((error)=>{
							reject(error);
						});
					});

				});
			 }).catch((error)=>{
				this.ajaxHandler.handleError(error);
			});;
		 }

		 handleDataExport(key){
			const miState = {...this.state};
			miState.dataToExport[key].value = !miState.dataToExport[key].value;
			this.setState(miState);
		}


	handleExportExcel() {
		let component = this;

		this.setState({loading:true});

		let dictDataToExport = {}
		Object.keys(this.state.dataToExport).forEach((key) =>{
			dictDataToExport[key] = this.state.dataToExport[key].value;
		});
		component.ajaxHandler.fetch('/infracciones/export-excel', {
			method: 'POST',
			body: JSON.stringify({
				colsAExportar: dictDataToExport,
				fechaInicio: this.state.filtroPeriodoInicio,
				fechaFin: this.state.filtroPeriodoFin,
				centroCostos: this.state.centroCosto ? this.state.centroCosto : null,
				infraccionesAnterioresFechaAlta: this.state.infraccionesAnterioresFechaAlta,
				bases: this.state.base ? this.state.base :null
			})
		}).then(response => {
			if (response.status === 200) {
				return response.blob();
			}
		}).then(fileBlob => {
			let fileUrl = URL.createObjectURL(fileBlob);
			$("<a />", {
				href: fileUrl,
				download: "fleet-infracciones.xlsx"
			}).appendTo("body").get(0).click();
		}).catch(() => {
		}).finally(() => {
			component.handleExportCancel();
			this.setState({ loading: false });
		});
	}
	handleDescargarAdjunto (adjunto) {
			if(adjunto){
				this.ajaxHandler.fetch('/infracciones/adjunto/'+adjunto, {
					method: 'GET',
					headers: {
						'Authorization-Token': localStorage.getItem('token')
					}
				}).then(response => {
					if (response.status === 200) {
						return response.blob();
					} else{
						swal(this.props.intl.formatMessage({ id: 'tipoEquipamientoGrid.download_adjunct.information_no_se_pudo_obtener_documento', defaultMessage: 'No se pudo obtener el documento!' }), "ERROR");
						throw true;
					}
				}).then(fileBlob => {
					let fileUrl = URL.createObjectURL(fileBlob);
					$("<a />", {
						href: fileUrl,
					 	target:"_blank"
					}).appendTo("body").get(0).click();
				})
			} else {
				swal(this.props.intl.formatMessage({ id: 'tipoEquipamientoGrid.download_adjunct.information_esta_infraccion_no_posee_adjuntos', defaultMessage: 'Esta infracción no posee adjuntos' }), "");
				throw true;
			}
	}

	handleExportCancel() {
		$('#export_modal').modal('toggle');
	}

	componentDidMount(nextProps, nextState) {
		window.scrollTo(0, 0);

		if(Security.hasPermission('INFRACCIONES_LISTAR')) {
		let component = this;

        this.ajaxHandler.subscribe(this);
        this.setState({ loading: true });

		if(this.props.location.state && this.props.location.state.filter){
			this.setState({ alertClick: this.props.location.state.filter});
			this.setState({ alertClickChild: this.props.location.state.filterChild});
		}
		if(this.props.location.state && this.props.location.state.filterMovilDetail){
			this.setState({ movilDetail: this.props.location.state.filterMovilDetail,
				seleccionado: "total",
				alertClick: "total",
				alertClickChild: null,
				alertsClickedChild: [],
				alertsClicked: {
					"pagadas": false,
					"pendientes_confirmacion": false,
					"pendientes_pago": false,
					"total": true
				}
			});
		}

		this.getUltimaActualizacion();

		this.calcularUnidadTiempo().then(unidad=>{
			let url = this.getUrlSparkline(unidad);

		Promise.all([
			//this.getData(url, true),
			fetch(url, {
				method: 'POST',
				body: JSON.stringify({
					filterColumns: this.state.filtersColumns
				}),
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization-Token': localStorage.getItem("token")
				}
			}),
			this.getData('/bases/select'),
			this.getData('/centros-costos/select'),
			//this.getData('/regiones/select')
		]).then((data) => {

			let bases = data[1] ? data[1] : [];
			let cecos = data[2] ? data[2] : [];
			//let regiones = data[3] ? data[3] : [];
			let filters = {
				estado: [{value: '', label: component.props.intl.formatMessage({ id: 'infraccionesGrid.column_state_filter.select_todos', defaultMessage: 'Todos' })},{value: 'INVALIDA', label: component.props.intl.formatMessage({ id: 'infraccionesGrid.column_state_filter.select_invalidas', defaultMessage: 'Inválidas' })},{value: 'VALIDA', label: component.props.intl.formatMessage({ id: 'infraccionesGrid.column_state_filter.select_validas', defaultMessage: 'Válidas' })}, {value: 'SIN_COMPROBACION', label: component.props.intl.formatMessage({ id: 'infraccionesGrid.column_state_filter.select_sin_comprobacion', defaultMessage: 'Sin Comprobación' })}, {value: 'A_VALIDAR', label: component.props.intl.formatMessage({ id: 'infraccionesGrid.column_state_filter.select_a_validar', defaultMessage: 'A Validar' })}]
			};

			data[0].json().then((dataEstadisticas)=>{
				component.setState({estadisticas: dataEstadisticas || []});
			})

			component.setState({
				centroCostos: cecos,
				bases: bases,
				//regiones: regiones
			});

			this.table = $(this.refs.grid).DataTable(
				Object.assign({
					dom: '<t><"row mt-2"<"col-4" l><"col-4 text-center" i><"col-4" p>>',

					paging: true,
					stateSave: true,
					order: [[ 3, "desc" ]],

					stateSaveCallback: function(settings,data) {
						localStorage.setItem( 'DataTables_InfraccionesGrid', JSON.stringify(data) );
					},
					stateLoadCallback: function(settings) {
						return JSON.parse( localStorage.getItem( 'DataTables_InfraccionesGrid' ) );
					},
					serverSide: true,
					processing: true,
					ajax: {
						type: 'GET',
                        url: Config.get('apiUrlBase') + this.dataService +'/'+ component.state.filtroPeriodoInicio+'/'+component.state.filtroPeriodoFin,
						data: function(d){

							return $.extend( {}, d, {
								"extra": component.state.alertClick,
								"extraChild": component.state.alertClickChild,
								"bases": component.state.base ? component.state.base : null,
								"centroCostos": component.state.centroCosto ? component.state.centroCosto : null,
								"infraccionesAnterioresFechaAlta": component.state.infraccionesAnterioresFechaAlta,
								"extraFilterMovilDetail": component.state.movilDetail ? component.state.movilDetail : null
							} );
						},

						headers: {
							'Authorization-Token': localStorage.getItem("token")
						},
						dataSrc: function (response) {
							return response.data;
						},
						error: function(xhr, error, thrown) {
							component.ajaxHandler.handleResponseErrorsIsValid(xhr);
						},
						cache: false,
						complete: function(response){

							Promise.all([
								fetch(Config.get('apiUrlBase') + '/infracciones/controles/alarmas', {
									method: 'POST',
									body: JSON.stringify({
										"filtroPeriodoInicio": component.state.filtroPeriodoInicio,
										"filtroPeriodoFin": component.state.filtroPeriodoFin,
										"bases": component.state.base,
										"centroCostos": component.state.centroCosto,
										"infraccionesAnterioresFechaAlta": component.state.infraccionesAnterioresFechaAlta,
										"extraFilterMovilDetail": component.state.movilDetail ? component.state.movilDetail : null
									}),
									headers: {
										'Accept': 'application/json',
										'Content-Type': 'application/json',
										'Authorization-Token': localStorage.getItem("token")
									}
								})
							]).then((data) => {
								data[0].json().then((controles)=>{
									let alertsClicked = [];
									for(let alarm in controles){
										alertsClicked[controles[alarm].filter] = component.state.alertsClicked[controles[alarm].filter];
									}
									component.setState({summaryAlerts: controles, alertsClicked: alertsClicked});
								}).catch((error)=>{
									console.log(error);
								});
							}).catch(function(error) {
								component.ajaxHandler.handleError(error);
							});

						}
					},
					initComplete: function (settings) {
						let stateColumns = JSON.parse(localStorage.getItem('DataTables_InfraccionesGrid')).columns;

						var sets = settings;
						var index = 0;
						this.api().columns().every( function () {
							var column = this;

							if(sets.aoColumns[index].filterType){
								if(sets.aoColumns[index].filterType === 'select') {
									var select = $('<select class="btn-dt-filter" required><option value="" selected>'+component.props.intl.formatMessage({ id: 'infraccionesGrid.columns_searh_filter.buscar', defaultMessage: 'Buscar...' })+'</option></select>')
										.appendTo( $(column.footer()).empty() )
										.on( 'change', function (e) {
											var val = $.fn.dataTable.util.escapeRegex($(this).val());
											column
												.search( val ? val : '', true, false )
												.draw();

											//como ocurrió un cambio en la columna se aplican los mismos filtros al kpi
											//TODO: por ahora no hay filtros de tipo select en la grilla, si se agregan testear que lo siguiente funcione
											component.updateFilters(e.target.name, val);
										});
									if(filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
										filters[sets.aoColumns[index].name].map(e => {
											select.append('<option value="'+e.value+'">'+e.label+'</option>');
											return true;
										});
									} else {
										column.data().unique().sort().each( function ( d, j ) {
											select.append( '<option value="'+d+'">'+d+'</option>' );
										});
									}
									if(stateColumns[index].search.search){
										select.val(stateColumns[index].search.search);
										component.updateFilters(sets.aoColumns[index].name, stateColumns[index].search.search, false);
									}
								}
								if(sets.aoColumns[index].filterType === 'input') {
									var input = $('<input type="text" class="btn-dt-filter" name="'+sets.aoColumns[index].name+'" placeholder="'+component.props.intl.formatMessage({ id: 'infraccionesGrid.columns_searh_filter.placeholder_buscar', defaultMessage: 'Buscar...' })+'" />');
									if(stateColumns[index].search.search){
										input.val(stateColumns[index].search.search);
										component.updateFilters(sets.aoColumns[index].name, stateColumns[index].search.search, false);
									}
									input.appendTo($(column.footer()).empty());
									input.on( 'keyup change', function (e) {
										if ( column.search() !== this.value ) {
											column
											.search( this.value )
											.draw()
											.ajax.reload(null, false);

											//como ocurrió un cambio en la columna se aplican los mismos filtros al kpi
											component.updateFilters(this.name, this.value);

										}
									});
								}
							//menu-estado
							if (sets.aoColumns[index].filterType === 'menuEstado') {

								var menuEstado = $('<div class="btn-group dropright"><button type="button" class="dropdown-toggle button-embudo" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="ft-filter"></i></button><div class="dropdown-menu" id="menuEstadoDrop"><h6 class="dropdown-header">'+component.props.intl.formatMessage({ id: 'infraccionesGrid.column_state_filter.title_estado', defaultMessage: 'Estado' })+'</h6></div></div>')
										.appendTo($(column.footer()).empty());

										filters[sets.aoColumns[index].name].map(e => {
											$('#menuEstadoDrop').append('<button class="dropdown-item" value="' + e.value + '">' + e.label + '</button>');
											return true;
										});
										$('#menuEstadoDrop').on('click', function(e) {
											var val = $.fn.dataTable.util.escapeRegex($(this).val());
										column
												.search(e.target.value ? e.target.value : '', true, false)
											.draw();
											//como ocurrió un cambio en la columna se aplican los mismos filtros al kpi
											component.updateFilters("estado",e.target.value);
										});
								if (stateColumns && stateColumns[index].search.search) menuEstado.val(stateColumns[index].search.search);

							}
							}
							index++;
							return '';
						});
						//cuando se terminan de cargar los filtros de todas las columnas refresco los kpis
                        component.cargarDatosFiltrados();

						$('tfoot tr').appendTo('thead');

					},
					columns: [
						{
							orderable: true,
							title: component.props.intl.formatMessage({ id: 'infraccionesGrid.column_due_date.label_fecha_venc', defaultMessage: 'Fecha Venc.' }),
							data: 'fechaVencimiento',
							name: 'fechaVencimiento',
							filterType: 'input',
							className: 'all',
							render: function (data, type, full, meta) {
								let result = '';
								if (moment(data,"YYYY-MM-DD HH:mm:SS").isValid()) {
									result = Timezone.getDateForClient(data, 'YYYY-MM-DD HH:mm:SS', 'DD/MM/YYYY');
								}
								return result;
							}
						},
						{
							title: '',
							data: 'estado',
							name: 'estado',
							filterType: 'menuEstado',
							width: 3,
							padding: 0,
							orderable: false,
							className: 'p-0 all embudo',
							render: function(data, type, full, meta) {
								return '';
							},
							createdCell: function(td, cellData, rowData, row, col) {
								$('div.tooltip[role="tooltip"]').tooltip('dispose');
								$('div.tooltip[role="tooltip"]').remove();
								$(td).attr('data-togle', 'tooltip');
								$(td).attr('data-placement', 'top');
								$(td).tooltip();
								if (cellData == 'VALIDA')
									$(td).addClass('status-green');
								if (cellData == 'INVALIDA')
									$(td).addClass('status-red');
								if (cellData == 'A_VALIDAR')
									$(td).addClass('status-yellow');
								if (cellData == 'SIN_COMPROBACION')
									$(td).addClass('status-grey');
							}
						},
						{
							title: component.props.intl.formatMessage({ id: 'infraccionesGrid.column_controller.label_controles', defaultMessage: 'Controles' }),
							name: 'controles',
							className: 'all',
							data: null,
							orderable: false,
							render: function(data, type, full, meta){

								let devolver = '<div class="row">';

								/* POSITION */
								if(full['estadoAVL'] === 'SIN_COMPROBACION')
									devolver += '<div class="col-4 p-0 div-position" data-toggle="tooltip" data-placement="top" data-html="true" title data-original-title="'+(!full['latitud'] ? component.props.intl.formatMessage({ id: 'infraccionesGrid.column_controller.state_SIN_COMPROBACION.title_sin_coordenadas_de_direccion_informada', defaultMessage: 'Sin coordenadas de dirección informada' }) : component.props.intl.formatMessage({ id: 'infraccionesGrid.column_controller.state_SIN_COMPROBACION.information_sin_posicion_de_Disp_satelital', defaultMessage: 'Sin Posición de Disp. Satelital' }))+'"><i class="fa fa-map-marker fa-xs infracciones-precio infracciones-precio-gris"></i></div>';

								if(full['estadoAVL'] === 'INVALIDA') {
									let metros = parseFloat(full['direccion_coincide']);
									if(metros){
										devolver += '<div class="col-4 p-0" style="cursor:pointer;"><i class="div-position fa fa-map-marker fa-xs combustibles-carga-rojo" data-toggle="popover" data-html=true data-placement="right" '
										devolver += ' title="'+component.props.intl.formatMessage({ id: 'infraccionesGrid.column_controller.state_INVALIDA.title_direccion_informada_a', defaultMessage: 'Dirección informada a ' })+ (metros > 1000 ? (metros/1000).toFixed(2)+component.props.intl.formatMessage({ id: 'infraccionesGrid.column_controller.state_INVALIDA.title_kilometros', defaultMessage: ' kilómetros' }) : metros.toFixed(2) + component.props.intl.formatMessage({ id: 'infraccionesGrid.column_controller.state_INVALIDA.title_metros', defaultMessage: ' metros' })) + component.props.intl.formatMessage({ id: 'infraccionesGrid.column_controller.state_INVALIDA.title_de_la_posicion_obtenida_en_el_AVL', defaultMessage: ' de la posición obtenida en el AVL.' })+'" ></i></div>';
									}
									else{
										devolver += '<div class="col-4 p-0 div-position" data-toggle="tooltip" data-placement="top" data-html="true" title data-original-title="'+(!full['latitud'] ? component.props.intl.formatMessage({ id: 'infraccionesGrid.column_controller.state_INVALIDA_metros_null.title_sin_coordenadas_de_direccion_informada', defaultMessage: 'Sin coordenadas de dirección informada' }) : component.props.intl.formatMessage({ id: 'infraccionesGrid.column_controller.state_INVALIDA_metros_null.title_sin_posicion_de_disp_satelital', defaultMessage: 'Sin Posición de Disp. Satelital' }))+'"><i class="fa fa-map-marker fa-xs combustibles-carga-rojo"></i></div>';
									}
								}
								if(full['estadoAVL'] === 'VALIDA')
									devolver+= '<div class="col-4 p-0" style="cursor:pointer;"><i class="div-position fa fa-map-marker fa-xs combustibles-carga-verde" data-toggle="popover" data-html=true data-placement="right" title="'+('')+ component.props.intl.formatMessage({ id: 'infraccionesGrid.column_controller.state_VALIDA.title_direccion', defaultMessage: 'Dir: ' }) +full['direccion']+ '"></i></div>';



								/* LIBRE DEUDA */
								if (full['estadoPago'] === 'VALIDA')
									devolver += '<div class="col-4 p-0 "><i class="fa fa-usd fa-xs infracciones-precio infracciones-precio-verde" data-toggle="tooltip" data-placement="top" '+
																	'data-original-title="'+component.props.intl.formatMessage({ id: 'infraccionesGrid.column_controller.state_payment_VALIDA.title_pago_confirmado', defaultMessage: 'Pago confirmado' })+'"></i></div>';
								if (full['estadoPago'] === 'A_VALIDAR')
									devolver += '<div class="col-4 p-0 "><i class="fa fa-usd fa-xs infracciones-precio infracciones-precio-amarillo" data-toggle="tooltip" data-placement="top" '+
																	'data-original-title="'+component.props.intl.formatMessage({ id: 'infraccionesGrid.column_controller.state_payment_A_VALIDAR.title_pagado_no_confirmado', defaultMessage: 'Pagado no Confirmado' })+'"></i></div>';

								if (full['estadoPago'] === 'INVALIDA')
											devolver += '<div class="col-4 p-0 "><i class="fa fa-usd fa-xs infracciones-precio infracciones-precio-rojo" data-toggle="tooltip" data-placement="top" '+
																		'data-original-title="'+component.props.intl.formatMessage({ id: 'infraccionesGrid.column_controller.state_payment_A_INVALIDA.title_pendiente_de_pago', defaultMessage: 'Pendiente de Pago' })+'"></i></div>';


								/* VENCIMIENTO */
								let fechaVencimientoStr = component.props.intl.formatMessage({ id: 'infraccionesGrid.column_controller.state_expiration.sin_vencimiento', defaultMessage: 'Sin vencimiento' })
								let color = "infracciones-precio-gris"
								let diffInDays = ''
								if (full.fechaVencimiento ) {
									diffInDays = Math.ceil(moment(full.fechaVencimiento).diff(moment(), 'hours') / 24)
									fechaVencimientoStr = component.props.intl.formatMessage({ id: 'infraccionesGrid.column_controller.state_expiration.faltan', defaultMessage: 'Faltan ' }) + diffInDays.toString() + component.props.intl.formatMessage({ id: 'infraccionesGrid.column_controller.state_expiration.dias_para_vencer', defaultMessage: ' días para vencer' })
								}

								devolver +=  '<div class="col-4 p-0">'
								if (full['estadoVencimiento'] === 'VALIDA') {
										color = "infracciones-precio-verde"
								}
								if (full['estadoVencimiento'] === 'INVALIDA') {
										fechaVencimientoStr = component.props.intl.formatMessage({ id: 'infraccionesGrid.column_controller.state_expiration_INVALIDA.vencida', defaultMessage: 'Vencida' })
										color = "infracciones-precio-rojo"
								}
								if (full['estadoVencimiento'] === 'A_VALIDAR') {
										color = "infracciones-precio-amarillo"
								}

								if (full['pagada'] == 'Si') {
										fechaVencimientoStr = component.props.intl.formatMessage({ id: 'infraccionesGrid.column_controller.state_expiration_VALIDA.abonada', defaultMessage: 'Abonada' }) + ' ' +(full.fechaAbono ? 'el '+ moment(full.fechaAbono).format('DD/MM/YYYY') : '');
								}


								devolver += '<i class="fa fa-clock-o fa-xs infracciones-precio ' + color + '" data-html="true" data-placement="top" data-toggle="tooltip" data-original-title="' + fechaVencimientoStr + '"></i></div>'
								devolver +=  '</div>'

								return devolver;
							},
							createdCell: function(td, cellData, rowData, row, col) {
								if(rowData['direccion_coincide'] === null){
									$(td).find('.div-position').tooltip();
								}else{
									$(td).find('.div-position').popover({content: '<div id="map'+rowData.id+'" class="map-popover"></div>'}).addClass('popover-combustibles');
									$(td).find('.div-position').on('inserted.bs.popover', function () {
										if(parseFloat(rowData['direccion_coincide']) > 500 ){
											component.initMap(rowData.id,{latitudInformada: parseFloat(rowData['latitud']), longitudInformada: parseFloat(rowData['longitud'])}, {latitudDetectada: parseFloat(rowData['latitud_detectada_avl']), longitudDetectada: parseFloat(rowData['longitud_detectada_avl'])});
										} else{
											component.initMap(rowData.id,{latitudInformada: parseFloat(rowData['latitud']), longitudInformada: parseFloat(rowData['longitud'])});
										}
									})
								}
								$(td).find('.infracciones-precio').tooltip();
								$(td).css({ 'min-width': '110px', 'max-width': '110px' });
							}
						},
						{
							title: component.props.intl.formatMessage({ id: 'infraccionesGrid.column_domain.label_dominio', defaultMessage: 'Dominio' }),
							data: 'dominio',
							name: 'dominio',
							padding: 0,
							orderable: false,
							className: 'p-0 all',
							filterType: 'input'
						},
						{
							orderable: true,
							title: component.props.intl.formatMessage({ id: 'infraccionesGrid.column_broadcast_date.label_fecha_emision', defaultMessage: 'Fecha Emisión' }),
							data: 'fechaEmision',
							name: 'fechaEmision',
							filterType: 'input',
							className: 'all',
							render: function (data, type, full, meta) {
								let result = '';
								if (moment(data,"YYYY-MM-DD HH:mm:SS").isValid()) {
									result = Timezone.getDateForClient(data, 'YYYY-MM-DD HH:mm:SS', 'DD/MM/YYYY');
								}
								return result;
							}
						},
						{
							title: component.props.intl.formatMessage({ id: 'infraccionesGrid.column_district.label_distrito', defaultMessage: 'Distrito' }),
							data: 'fuente',
							name: 'fuente',
							orderable: true,
							className: 'p-0 all',
							filterType: 'input'
						},
						{
							title: component.props.intl.formatMessage({ id: 'infraccionesGrid.column_number_acta.label_acta', defaultMessage: '# Acta' }),
							data: 'acta',
							name: 'acta',
							orderable: true,
							className: 'p-0 all',
							filterType: 'input'
						},{
							title: component.props.intl.formatMessage({ id: 'infraccionesGrid.column_base.label_dominio', defaultMessage: 'Base' }),
							name: 'baseDescripcion',
							data: 'baseDescripcion',
							className: 'all',
							orderable: false,
							filterType: 'input',
							render: function(data, type, full, meta) {
								if (type === 'filter') {
									return data
								} else {
									return data ? '<div data-togle="tooltip" data-placement="top" title="'+ full['baseDescripcion']+' - '+data +'">' + (full['baseDescripcion']+' - '+data).substring(0, 10) +'</div>' : '';
								}
							},
							createdCell: function(td, cellData, rowData, row, col) {
								$(td).find('div').tooltip();
							}
						},{
							title: component.props.intl.formatMessage({ id: 'infraccionesGrid.column_amount.label_importe', defaultMessage: 'Importe' }),
							data: 'importe',
							name: 'importe',
							className: 'all',
							filterType: 'input',
							render: function(data, type, full, meta) {
								return data ? `
										<div data-toggle="tooltip"
													data-original-title="`+component.props.intl.formatMessage({ id: 'infraccionesGrid.column_amount.content_title_importe', defaultMessage: 'Importe Original' })+`">
											$ ${full['importe']}
										</div>`: '';
							},
						},
						{
							title: component.props.intl.formatMessage({ id: 'infraccionesGrid.column_abonado.label_abonado', defaultMessage: 'Abonado' }),
							data: 'importeAbonado',
							name: 'importeAbonado',
							className: 'all',
							filterType: 'input',
							render: function(data, type, full, meta) {
								return data ? `
										<div	data-toggle="tooltip"
													data-original-title="`+component.props.intl.formatMessage({ id: 'infraccionesGrid.column_abonado.content_title_abonado', defaultMessage: 'Importe Abonado' })+`">
											$ ${full['importeAbonado']}
										</div>`: '';
							},
						},{
							title: component.props.intl.formatMessage({ id: 'infraccionesGrid.column_driver.label_dominio', defaultMessage: 'Conductor' }),
							data: 'conductorName',
							name: 'conductorName',
							orderable: false,
							className: 'p-0 all',
							filterType: 'input'
						},{
							title: component.props.intl.formatMessage({ id: 'infraccionesGrid.column_infraction.label_infraccion', defaultMessage: 'Infraccion' }),
							data: 'infraccion',
							name: 'infraccion',
							orderable: true,
							className: 'p-0 all',
							filterType: 'input',
							render: function(data, type, full, meta) {
								if (type === 'filter') {
									return data
								} else {
									return data ? '<div data-togle="tooltip" data-placement="top" title="'+ full['codigoInfraccion']+' - '+data +'">' + (full['codigoInfraccion']+' - '+data).substring(0, 20) +'</div>' : '';
								}
							},
							createdCell: function(td, cellData, rowData, row, col) {
								$(td).find('div').tooltip();
							}
						},{
							title: component.props.intl.formatMessage({ id: 'infraccionesGrid.column_detail.label_detalle', defaultMessage: 'Detalle' }),
							data: 'descripcion',
							name: 'descripcion',
							orderable: false,
							className: 'p-0 all',
							render: function(data, type, full, meta) {
								if (type === 'filter') {
									return data
								} else {
									return data ? '<div data-togle="tooltip" data-placement="top" title="'+ data +'">' + data.substring(0, 20) +'</div>' : '';
								}
							},
							createdCell: function(td, cellData, rowData, row, col) {
								$(td).find('div').tooltip();
							},
							filterType: 'input'
						},{
								orderable: false,
								data: null,
								className: "all",
								width: '120px',
								render: function (data, type, full, meta) {

									let html =

                  (Security.hasPermission('INFRACCIONES_VISUALIZAR') ? `
									<button class="action view btn btn-sm btn-icon btn-dt-grid text-success" data-title="`+component.props.intl.formatMessage({ id: 'infraccionesGrid.column_actions.button_title_ver', defaultMessage: 'Ver' })+`" data-togle="tooltip" data-placement="top">
										<i class="fa fa-eye fa-xs"></i>
									</button>` : '') +

									(Security.hasPermission('INFRACCIONES_MODIFICAR') ? `
									<button class="action edit btn btn-sm btn-icon btn-dt-grid text-success" data-title="`+component.props.intl.formatMessage({ id: 'infraccionesGrid.column_actions.button_title_editar', defaultMessage: 'Editar' })+`" data-togle="tooltip" data-placement="top">
										<i class="fa fa-pencil fa-xs"></i>
									</button>` : '') +
									(Security.hasPermission('INFRACCIONES_INFORMAR_PAGO') ? `
									<button ${full.fechaAbono?'disabled':''} class="action pay btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'infraccionesGrid.column_actions.button_title_informar_pago', defaultMessage: 'Informar pago' })+`" data-togle="tooltip" data-placement="top">
										<i class="fa fa-usd fa-xs"></i>
									</button>` : '') +

                  (Security.hasPermission('INFRACCIONES_ADJUNTAR') ? `
									<button ${!full.actaAdjunto?'disabled':''} class="action adjunto btn btn-sm btn-icon btn-dt-grid ${full.actaAdjunto?'text-success':''}" title="`+component.props.intl.formatMessage({ id: 'infraccionesGrid.column_actions.button_title_descargar_acta', defaultMessage: 'Descargar acta' })+`" data-togle="tooltip" data-trigger="hover" data-placement="top">
										<i class="fa fa-download fa-xs"></i>
									</button>` : '')

									+ (Security.hasPermission('INFRACCIONES_VALIDAR') && (data.estadoAVL != 'VALIDA') ? `
									<button class="action approve btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'infraccionesGrid.column_actions.button_title_validar_Posicion', defaultMessage: 'Validar Posición' })+`" data-togle="tooltip" data-placement="top">
										<i class="fa fa-thumbs-up fa-xs"></i>
									</button>` : '')


									+ (Security.hasPermission('INFRACCIONES_ELIMINAR') ? `
									<button class="action delete btn btn-sm btn-icon btn-dt-grid text-danger" title="`+component.props.intl.formatMessage({ id: 'infraccionesGrid.column_actions.button_title_eliminar', defaultMessage: 'Eliminar' })+`">
										<i class="fa fa-trash fa-xs"></i>
									</button>
									`:'')

									return html;
								},
								createdCell: function(td, cellData, rowData, row, col) {
									$(td).find('button').tooltip();
								}
						}],
						drawCallback: function () {
							$(this).find('.action').on('click', function () {
								let data = component.table.row($(this).parents('tr')).data();
								let redirectTo;
								if($(this).hasClass('view')) redirectTo = component.props.match.url + '/' + data.id;
								if($(this).hasClass('edit')) redirectTo = component.props.match.url + '/' + data.id + '/edit';
								if($(this).hasClass('adjunto')) {
									component.handleDescargarAdjunto(data.actaAdjunto)
								}
								if($(this).hasClass('pay')) {
									component.setState({
										dialogInformarPago: {
											open: true,
											dominio: data.dominio,
											id: data.id
										}
									});
									$('#pagarInfranccion_modal').modal({backdrop: 'static'});
									$("#pagarInfranccion_modal").on('hidden.bs.modal', function () {
										component.setState({
											...this.state,
											dialogInformarPago: {
												open: false,
												dominio: null,
												id: null
											}
										});
									});
								}
								component.setState({
									redirectTo: redirectTo
								});

								if ($(this).hasClass('approve')) {
									swal({
										title: component.props.intl.formatMessage({ id: 'infraccionesGrid.modal_validate_location_infraction.pasar_a_valida', defaultMessage: 'Pasar a Válida' }),
										text: component.props.intl.formatMessage({ id: 'infraccionesGrid.modal_validate_location_infraction.confirm_validacion', defaultMessage: '¿Confirma que desea validar la localización de la infracción?' }),
										icon: "warning",
										content: {
											element: "textarea",
											attributes: {
												placeholder: "Observaciones",
												id: 'confirm-observacionesCambioEstado'
											}
										},
										buttons: {
											confirm: {
												text: component.props.intl.formatMessage({ id: 'infraccionesGrid.modal_validate_location_infraction.confirm_validacion.si', defaultMessage: 'Si' }),
												value: true,
												visible: true,
												className: "btn btn-primary",
												closeModal: false
											},
											cancel: {
												text: component.props.intl.formatMessage({ id: 'infraccionesGrid.modal_validate_location_infraction.confirm_validacion.no', defaultMessage: 'No' }),
												value: null,
												visible: true,
												className: "btn btn-danger",
												closeModal: true,
											}
										}
									}).then((isConfirm) => {
										if (isConfirm) {
											component.ajaxHandler.fetch('/infracciones/' + data.id + '/validar', {
												method: 'POST',
												body: JSON.stringify({
													observaciones: $('#confirm-observacionesCambioEstado').val()
												}),
												headers: {
													'Accept': 'application/json',
													'Content-Type': 'application/json',
													'Authorization-Token': localStorage.getItem("token")
												}
											}).then((response) => {
												if(response.status === 200) {
													component.table.ajax.reload(null, false);
													swal(component.props.intl.formatMessage({ id: 'infraccionesGrid.modal_validate_location_infraction.information_infraccion_validada', defaultMessage: 'Infracción Validada.' }), "", "success");
												}
											}).catch((error) => {
												component.ajaxHandler.handleError(error);
											});
										}
									});
								}

								if($(this).hasClass('delete')) {
									swal({
									title: component.props.intl.formatMessage({ id: 'infraccionesGrid.modal_delete.confirm_eliminacion', defaultMessage: '¿Confirma la eliminación?' }),
									text: null,
									icon: "warning",
									buttons: {
										confirm: {
											text: component.props.intl.formatMessage({ id: 'infraccionesGrid.modal_delete.confirm_si', defaultMessage: 'Si' }),
											value: true,
											visible: true,
											className: "btn btn-primary",
											closeModal: false
										},
										cancel: {
											text: component.props.intl.formatMessage({ id: 'infraccionesGrid.modal_delete.confirm_no', defaultMessage: 'No' }),
											value: null,
											visible: true,
											className: "btn btn-danger",
											closeModal: true,
										}
									}
								})
								.then((isConfirm) => {
									if (isConfirm) {
										fetch(Config.get('apiUrlBase') + '/infracciones/' + data.idMovil + '-' + data.fuente + '-' + data.origen + '-' + data.acta, {
											method: 'DELETE',
											headers: {
												'Accept': 'application/json',
												'Content-Type': 'application/json',
												'Authorization-Token': localStorage.getItem("token")
											}
										})
										.then(function(response) {
											component.table.ajax.reload(null, false);
											swal(component.props.intl.formatMessage({ id: 'infraccionesGrid.modal_delete.information_eliminado', defaultMessage: 'Eliminado!' }), "", "success");
										})
										.catch(function(error) {
											swal(component.props.intl.formatMessage({ id: 'infraccionesGrid.modal_delete.information_error', defaultMessage: 'Error' }), "", "error");
										});
									}
								});
								}
							});
						},
				}, datatablesConfig(this.props.intl))
			);

			new $.fn.dataTable.Buttons( this.table, {
				buttons: [
					{
						name: 'filterReset',
						text: '<img id="filterReset" class="filter-reset" src="'+LimpiarFiltros+'"></i>',
						titleAttr: component.props.intl.formatMessage({ id: 'infraccionesGrid.tools_filter_Reset.title_restaurar_filtros_ordenamiento', defaultMessage: 'Restaurar filtros y ordenamiento' }),
						action: function (e, dt, node, config) {
							var index = 0;

							component.setState({loading: true});

							component.setState({
								centroCosto: null,
								base: null,
								filtroPeriodoInicio: null,
								filtroPeriodoFin: null,
								periodoSelect: {id:0, label:"Todo"}
							});

							component.table.columns().every(function() {
								var column = this;

								if (dt.settings()[0].aoColumns[index].filterType) {
									column.search('', true, false);
								}
								index++;
								return null;
							});
							$(dt.table().node()).find('thead tr th *[class$="-filter"]').each((index, element) => {
								$(element).val('');
							});

							dt.table().order([ 3, 'desc' ]);


							let copyClicked = component.state.alertsClicked;
							for(let alarm in component.state.alertsClicked){
								copyClicked[alarm] = false;
							}

							let copyClickedChild = component.state.alertsClickedChild;
							for(let alarmChild in component.state.alertsClickedChild){
								copyClickedChild[alarmChild] = false;
							}

							component.setState({
								filtersColumns: {},
								alertClick: null,
								alertClickChild: null,
								alertsClicked: copyClicked,
								alertsClickedChild: copyClickedChild,
								movilDetail: null
							})

							component.cargarDatosFiltrados().finally(()=>{
								component.setState({loading:false});
							});
						}
					},
					{
						name: 'ultimaActualizacion',
						text: '<img id="ultimaActualizacion" class="ultima-actualizacion" src="'+Calendario+'"></i>',
						titleAttr: (!ConfigBusiness.get('infracciones.fuentes') || ConfigBusiness.get('infracciones.fuentes') === '') ? "No hay fuentes de infracciones configuradas." : component.props.intl.formatMessage({ id: 'infraccionesGrid.tools_ultima_importacion.title_ultima_importacion', defaultMessage: 'Última Actualizacion:' })+this.state.ultimaActualizacion,
					},
					{
			extend: 'print',
			text: '<i class="ft-printer"></i>',
						title: '',
						customize: function(win)
						{
							var css = '@page { size: landscape; }',
								head = win.document.head || win.document.getElementsByTagName('head')[0],
								style = win.document.createElement('style');

							style.type = 'text/css';
							style.media = 'print';

							if (style.styleSheet) {
								style.styleSheet.cssText = css;
							}	else {
								style.appendChild(win.document.createTextNode(css));
							}

							head.appendChild(style);

							$(win.document.body)
								.css( 'font-size', '10pt' )
								.prepend(`<div class="container">
														<div class="row mb-2">
															<div class="col-6">
																<img style="height: 35px" src="`+LogoCompany+`" />
															</div>
															<div class="col-6	text-right">
																<img style="height: 40px" src="`+LogoVecFleet+`" />
															</div>
														</div>
														<div class="row mb-2">
															<div class="col-12" style="border-bottom: 1px solid #000;">
																<h2>`+component.props.intl.formatMessage({ id: 'infraccionesGrid.tools_print_content.header_listado_de_infracciones', defaultMessage: 'Listado de Infracciones' })+`</h2>
															</div>
														</div>
													</div>`);

							$(win.document.body).find( 'table' )
								.addClass( 'compact' )
								.css( 'font-size', 'inherit' );
						},

				exportOptions: {
					columns: component.columnsToPrint
							},

				titleAttr: component.props.intl.formatMessage({ id: 'infraccionesGrid.tools_print.title_imprimir', defaultMessage: 'Imprimir' })
				}, {
					extend: 'colvis',
					name: 'columns',
					text: '<i class="la la-columns"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'infraccionesGrid.tools_select_columns.title_select_columns', defaultMessage: 'Seleccionar columnas' })
				}, {
					name: 'exportExcel',
					text: '<i class="ft-upload"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'infraccionesGrid.tools_export.title_exportar_excel', defaultMessage: 'Exportar a Excel' }),
					action: function (e, dt, node, config) {
						component.setState({
							exportFechaDesde: moment().startOf('year'),
							exportFechaHasta: moment()
						}, () => {
							$('#export_modal').modal({backdrop: 'static'});
						});
					}
				},
				{
					text: '<i class="ft-download"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'infraccionesGrid.tools_import.title_importar', defaultMessage: 'importar' }),
					action: function(e, dt, node, config){
						$('#import_modal').modal({backdrop: 'static'});
					}
		}]
			});



			this.table.buttons( 0, null ).container().appendTo('#buttons');

			$('#dataTable tfoot th').each( function () {
				var title = $(this).text();
				if(title !== '') $(this).html( '<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'infraccionesGrid.columns_searh_filter.dataTable_buscar', defaultMessage: 'Buscar ...' })+'" />' );
			});

			this.table.columns([3,4,5]).every( function () {
				var that = this;
				$( 'input', this.footer()).on( 'keyup change', function () {
					if ( that.search() !== this.value ) {
						that
							.search( this.value )
							.draw()
							.ajax.reload(null, false);
					}
				});
				return null;
			});

			$('.buttons-collection').on("click",function(e){ e.stopPropagation();
				if($('.dt-button-collection>.dropdown-menu').css('display')=='none')
					$('.dt-button-collection>.dropdown-menu').css('display','block');
			});
		}).catch(function(error) {
			component.ajaxHandler.handleError(error);
		}).finally(() => {
			component.setState({
				loading: false
			});
		});
	});
	}
	$('html').on('click', function(e) {
		if (typeof $(e.target).data('original-title') == 'undefined' &&
		   !$(e.target).parents().is('.popover')) {
		  $('[data-original-title]').popover('hide');
		}
	  });
}

getUrlSparkline(unidad){
    let url = new URL(Config.get('apiUrlBase') + '/infracciones/sparkline/'+this.state.filtroPeriodoInicio+'/'+this.state.filtroPeriodoFin+'/'+unidad);
    let params = {
        bases: this.state.base ? this.state.base : '',
        regiones: this.state.region ? this.state.region : '',
        subRegiones: this.state.subRegion ? this.state.subRegion : '',
        centroCostos: this.state.centroCosto ? this.state.centroCosto : '',
		infraccionesAnterioresFechaAlta: this.state.infraccionesAnterioresFechaAlta
    };

    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    return url;
}

initMap(id,markerInformado, markerDetectado) {

	let lat = markerInformado['latitudInformada'];
	let long = markerInformado['longitudInformada'];
	let zoom = 14;
	let label = "";
	if(markerDetectado){
		lat = (markerDetectado['latitudDetectada'] + lat)/2;
		long = (markerDetectado['longitudDetectada'] + long)/2;
		zoom = 6;
		label = "I";
	}
	var centro = {lat: lat, lng: long};
	this.map = new window.google.maps.Map(document.getElementById('map'+id), {
			zoom: zoom,
			center: centro
	});
	// Place a draggable marker on the map
	this.marker = new window.google.maps.Marker({
			position: {lat: markerInformado['latitudInformada'], lng: markerInformado['longitudInformada']},
			map: this.map,
			title: this.props.intl.formatMessage({ id: 'infraccionesGrid.initMap_googleMaps.title_direccion_informada', defaultMessage: 'Dirección Informada' }),
			label: label
	});

	if(markerDetectado){

		this.marker = new window.google.maps.Marker({
			map: this.map,
			title: this.props.intl.formatMessage({ id: 'infraccionesGrid.initMap_googleMaps.title_direccion_detectada', defaultMessage: 'Dirección Detectada' }),
			position: {lat: markerDetectado['latitudDetectada'], lng: markerDetectado['longitudDetectada']},
			label: "D"
		});
	}

	window.google.maps.event.addListener(this.marker, 'dragend', this.cambiarMarcador);

}

placeMarkerAndPanTo(event) {
	this.geocodeLatLng(event.latLng.lat(), event.latLng.lng());
}

geocodeLatLng(lat, lng) {
	var geocoder = new window.google.maps.Geocoder;
	var latlng = {lat: parseFloat(lat), lng: parseFloat(lng)};
	let component;
	geocoder.geocode({'location': latlng}, this.geocodeLocation);
}

geocodeLocation(results, status) {

if (status === 'OK') {
		if (results[0]) {

				//this.fillInAddress(results[0], false).then(()=>{this.actualizarUbicacionInput();});
				//this.actualizarUbicacionInput();
		}
}
}

	handleNew(event) {
		this.setState({
			redirectTo: this.props.match.url + '/add'
		});
	}

	componentWillUnmount() {
		$('div.tooltip[role="tooltip"]').tooltip('dispose');
		$('div.tooltip[role="tooltip"]').remove();
		$('div.popover[role="tooltip"]').remove();
		if(this.table) this.table.destroy();
		this.ajaxHandler.unsubscribe();
	}

	calcularUnidadTiempo(){

		return new Promise((resolve)=>{
			let diferencia = moment(this.state.filtroPeriodoFin).diff(moment(this.state.filtroPeriodoInicio),"days");
				let unidad = "";
					if(diferencia <=14){
					unidad = null;
					} else if(diferencia > 14 && diferencia <= 63){
					unidad = "week";
					} else if(diferencia > 63 && diferencia <= 365 ){
					unidad = "month";
					} else{
					unidad = "year";
					}

				this.setState({unidad: unidad}, ()=>{resolve(unidad);});
		});
	}

	updateFilters(name, value, isChange=true){
		let filterC = JSON.parse(JSON.stringify(this.state.filtersColumns));
		if(value === ''){
			delete filterC[name];
		} else{
			filterC[name] = value;
		}
		this.setState({ filtersColumns: filterC },
			()=>{
				if(isChange) this.cargarDatosFiltrados()
			}
		);
	}

	getUltimaActualizacion() {

	this.setState({loading:true});

	Promise.all([
		this.ajaxHandler.getJson('/infracciones/ultima-actualizacion'),
		]).then((data) => {
			let ultimaActualizacionData = '';

			data[0].forEach((item) => {
				let row = "\n"+item['fuente']+" :  "+item['fecha'];
				ultimaActualizacionData += row;
			});

		this.setState({
			ultimaActualizacion : ultimaActualizacionData
			  });

	})
	.catch(function(error) {
		console.log(error);
	}).finally(() => {
		this.setState({ loading: false });
	});


	};

	render() {
	    return (
	    	<React.Fragment>
				{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				{this.state.loading && <Loading />}
				{this.state.summaryAlerts &&
					<section className="combustiblesWidget">
						<div className="row">
							{this.state.summaryAlerts.map((alert, index)=>{
								return(
									<div key={index} className={"col-xl-3 col-lg-6 col-12"} style={{ paddingRight: "0.5rem", paddingLeft: "0.5rem" }}>
										<AdvancedCardInfo
											id={ index }
											values={ alert }
											seleccionado={ this.seleccionado }
											onClickAlert={ this.handleAlertClick }
											backColor={ !this.state.alertsClicked[alert.filter] ? alert.backColor : this.selectedColor }
											backColor2={ !this.state.alertsClicked[alert.filter] ? alert.backColor : this.selectedColor2 }
										></AdvancedCardInfo>
									</div>
								);
							})}
						</div>
					</section>
				}
				<section id="minimal-gradient-statistics-bg" className="combustiblesWidget">
					<div className="row">
						{this.state.estadisticas.map((widget, index)=>{ if(widget){
							if (widget.text==="widget_map_location")
								{
									return(
									<div key={index} className="col-xl-3 col-lg-6 col-12">
										<WidgetMapChart id={index} text={this.textos[widget.text]} value={widget.value} description={widget.description} iconClass={widget.iconClass} mainColorClass={widget.mainColorClass} hasProgressBar={widget.hasProgressBar} progressBarValue={widget.progressBarValue} datos={widget.datos} entidad="infracciones"/>
										{/* <WidgetMapChart id={index} text={this.textos[widget.text]} /> */}
									</div>
									);
								}
								//else{
								//	return(
								//	<div key={index} className="col-xl-3 col-lg-6 col-12">
								//		<WidgetSparkline id={index} text={this.textos[widget.text]} value={widget.value} description={widget.description} iconClass={widget.iconClass} mainColorClass={widget.mainColorClass} hasProgressBar={widget.hasProgressBar} progressBarValue={widget.progressBarValue} datos={widget.datos} entidad="infracciones" />
								//	</div>
								//	);
								//}
							}
						})}
					</div>
				</section>
    			<div className="row mt-2">
					<div className="col-12">
			    		<div className="card">
							<div className="card-content collpase show">
			            		<div className="card-body card-dashboard">
									<div className="container-fluid">
										<div className="row dt-icons" style={{marginBottom: "1em"}}>
											<div className="col-6">
                                            {Security.renderIfHasPermission('INFRACCIONES_CREAR', (
												<div className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon" onClick={this.handleNew.bind(this)}>
													<i className="ft-plus"></i>
												</div>
                                            ))}
											</div>
											<div className="col-6" id="buttons">	</div>
										</div>
										<div className="row">
											<div className="from-group col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-1" style={{display:"flex"}}>
												<label className="col-3" style={{padding:"5px", marginTop:"5px"}}><FormattedMessage id="infraccionesGrid.filter_datatable.label_periodo" defaultMessage="Período: "/></label>
													<Select
														id="periodo"
														name="periodo"
														clearable={false}
														options={this.state.periodos}
														optionComponent={GravatarOption}
														valueKey="id"
														labelKey="label"
														className="col-9 select-periodo"
														value={this.state.periodoSelect ? this.state.periodoSelect : null}
														onChange={(e) => this.handleSelectFormChange("periodo",e)}
													/>
											</div>
											<div className="from-group col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-1" style={{display:"flex"}}>
												<label className="col-3" style={{padding:"5px", marginTop:"5px"}}><FormattedMessage id="infraccionesGrid.filter_datatable.label_base" defaultMessage="Base: "/></label>
												<Select
													id="base"
													name="base"
													clearable={true}
													options={this.state.bases}
													valueKey="value"
													labelKey="label"
													className="col-9 select-periodo"
													value={this.state.base ? this.state.base : null}
													onChange={(e) => this.handleSelectFormChange("base",e)}
													multi
												/>
											</div>
											<div className="from-group col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-1" style={{display:"flex"}}>
												<label className="col-4" style={{padding:"5px", marginTop:"5px"}}><FormattedMessage id="infraccionesGrid.filter_datatable.label_centro_costos" defaultMessage="Centro de Costos:"/> </label>
												<Select
													id="centroCosto"
													name="centroCosto"
													clearable={true}
													options={this.state.centroCostos}
													valueKey="value"
													labelKey="label"
													className="col-8 select-periodo"
													value={this.state.centroCosto ? this.state.centroCosto : null}
													onChange={(e) => this.handleSelectFormChange("centroCosto",e)}
													multi
												/>
											</div>
											<div className="from-group col-sm-12 col-md-12 col-lg-12 col-xl-7 mb-1" style={{display:"flex"}}>
												<label className="col-sm-6 col-md-6 col-lg-6 col-xl-6" style={{padding:"5px", marginTop:"5px"}}><FormattedMessage id="infraccionesGrid.filter_datatable.label_infracciones_anteriores_fecha_alta" defaultMessage="Ocultar Infracciones Anteriores a la Fecha de Alta de los Móviles"/> </label>
												<Switch
													onChange={(e) => this.handleSelectFormChange("infraccionesAnterioresFechaAlta",e)}
													checked={this.state.infraccionesAnterioresFechaAlta}
													/*disabled={filtersLoading}*/
													offColor="#FF4961"
													onColor="#28D094"
												/>
											</div>
										</div>
									</div>
									<div className="table-responsive">
										<table id="dataTable" className="table nowrap server-side table-hover" ref="grid" width="100%">
											<tfoot>
												<tr style={{backgroundColor: '#fff'}}>

													<th className=""></th>
													<th className=""></th>
													<th className=""></th>
													<th className=""></th>
													<th className=""></th>
													<th className="p-0"></th>
													<th className="p-0"></th>
													<th className="p-0"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="p-0"></th>
													<th className=""></th>
													<th className=""></th>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="modal fade" tabIndex="-1" id="pagarInfranccion_modal" role="dialog" aria-labelledby="modalPagarInfracción">
					<div className="modal-dialog modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-header bg-fleet">
								<h4 className="modal-title text-white" id="h_modalPagarInfracción"><i className="ft-info align-middle icon-modal-title"></i>{`Informar Pago (${this.state.dialogInformarPago.dominio})`}</h4>
							</div>
							<div className="modal-body modal-grey modal-mh ovf-x-hidden pagarInfranccion_modal_content">
							<InfraccionesPago open={this.state.dialogInformarPago.open} id={this.state.dialogInformarPago.id} action={"PAY"} callbackClose={this.handleCloseModal} callbackCancel={this.handleCancelModal} callbackSubmit={this.handleInformarPagoModal}
							/>
							</div>
						</div>
					</div>
				</div>
				<div className="modal fade" tabIndex="-1" id="export_modal" role="dialog" aria-labelledby="myModalLabel3">
		<div className="modal-xl modal-dialog" role="document">
			<div className="modal-content">
				<div className="modal-header bg-fleet">
					<h4 className="modal-title text-white" id="myModalLabel3"><i className="ft-download align-middle icon-modal-title"></i><FormattedMessage id="infraccionesGrid.modal_export_content.header_exportar_infraccionesGrid" defaultMessage=" Exportar InfraccionesGrid"/></h4>
					<button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
				</div>
				<div className="modal-body modal-grey modal-mh ovf-x-hidden">
					<div className="row">
						<div className="col-md-12">
							{/*<div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
								{this.state.errors.map((e, i) => <li key={i}>{e}</li>)}
							</div>*/}
							<form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
										<div className="form-body">
														<div className="card pull-up">
																	<div className="card-content">
																		<div className="card-body">
																			<h4 className="form-section">
																				<i className="la la-columns"></i><FormattedMessage id="infraccionesGrid.modal_export_content.label_columnas_a_exportar" defaultMessage=" Columnas a exportar"/>
																			</h4>


																			<div className="row m-1">
																				{Object.keys(this.state.dataToExport).map((key) =>{
																					let data = this.state.dataToExport[key];
																					return (<div className=" col-md-3" key = {key}>
																						<label className="col-md-6 label-control col-form-label" htmlFor={key}>
																							{data.title}:
																						</label>
																						<Switch
																							onChange = {(event) => this.handleDataExport(key)}
																							checked = {data.value ? true : false}
																							value={data.value}
																							id = {key}
																							name = {key}
																							offColor = "#FF4961"
																							onColor = "#28D094"
																							disabled = {false}
																							className =  "col-md-6 align-middle"
																						/>
																					</div>
																					);
																				}
																				)}
																			</div>
																		</div>
																	</div>
																</div>
													</div>
											<div className="col-md-12 alert alert-info small" role="alert">
												<FormattedMessage id="infraccionesGrid.modal_export_content.information_esta_accion_puede_demorar_minutos." defaultMessage=" Columnas a exportar"/>
											</div>
									</form>
						</div>
					</div>
				</div>
				<div className="modal-footer modal-grey">
					<button type="button" className="btn btn-default btn-fleet" onClick={this.handleExportExcel.bind(this)} ><i className={this.state.loading ? 'la la-spinner spinner' : 'fa fa-download'}></i><FormattedMessage id="infraccionesGrid.modal_export_content.button_finish_exportat_a_excel" defaultMessage=" Exportar a Excel"/></button>
					<button type="button" className="btn btn-danger" onClick={this.handleExportCancel} ><i className="fa fa-times-circle"></i><FormattedMessage id="infraccionesGrid.modal_export_content.button_finish_cancelar" defaultMessage=" Cancelar"/></button>
				</div>
			</div>
		</div>
	</div>
	<div className="modal fade col-8" tabIndex="-1" id="import_modal" role="dialog" aria-labelledby="myModalLabel1" style={{margin:"auto"}}>
			<div className="modal-xl modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header bg-fleet">
						<h4 className="modal-title text-white" id="myModalLabel1">{/*<i className="la la-history align-middle icon-modal-title"></i>*/}<FormattedMessage id="infraccionesGrid.modal_import_content.header_importar" defaultMessage="Importar"/></h4>
						<button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
					</div>
					<div className="modal-body modal-grey modal-mh ovf-x-hidden">
							<form className="form">
								{/* {<CombustiblesImportar callbackClose={this.handleCloseModal} callbackCancel={this.handleCancelModal}/>} */}
							</form>
					</div>
					{/*<div className="modal-footer modal-grey">
						<button type="button" className="btn btn-default btn-fleet" data-dismiss="modal">Ok</button>
						</div>*/}
				</div>
			</div>
		</div>
	    	</React.Fragment>
	    );
  	}

	getData(service, completo = false) {

		let serviceURL = "";
		if(!completo)
			serviceURL = Config.get('apiUrlBase') + service;
		else
			serviceURL = service;
		return fetch(serviceURL, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization-Token': localStorage.getItem("token")
			}
		})
		.then(response => {
			return this.ajaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
		});
	}

}

export default injectIntl(InfraccionesGrid);
